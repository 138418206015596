<template>
  <section class="module--social-share">
    <slot></slot>
  </section>
</template>

<script>
  export default {
    mounted() {
      window.onload = function () {
        const url = window.location.href;
        const pageTitle = document.querySelector('head title').textContent;
        const urlTitle = encodeURIComponent(pageTitle);
        document
          .getElementById('facebookShareLink')
          .addEventListener('click', function () {
            const fbpopup = window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${urlTitle}`,
              'pop',
              'width=600, height=400, scrollbars=no'
            );
            return false;
          });
        document
          .getElementById('twitterShareLink')
          .addEventListener('click', function () {
            const twitterpopup = window.open(
              `https://twitter.com/intent/tweet?url=${url}&text=${urlTitle}&via=Samsara`,
              'pop',
              'width=600, height=400, scrollbars=no'
            );
            return false;
          });
        document
          .getElementById('linkedinShareLink')
          .addEventListener('click', function () {
            const linkedinpopup = window.open(
              `http://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${urlTitle}`,
              'pop',
              'width=600, height=400, scrollbars=no'
            );
            return false;
          });
        document
          .getElementById('emailShareLink')
          .addEventListener('click', function () {
            const emailpopup = window.open(
              `mailto:?body=${url}&subject=Read about ${urlTitle} via Samsara`,
              'pop',
              'width=600, height=400, scrollbars=no'
            );
            return false;
          });
      };
    },
  };
</script>

<style lang="scss">
  .module--social-share {
    .wrapper--content {
      margin: 0 auto;
      padding: 7px 0 3px;
      p {
        margin-right: 30px;
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
    li {
      @apply cursor-pointer list-none bg-cover;
      width: 30px;
      height: 30px;
      transition: 0.15s ease-in-out;
      margin-right: 10px;
    }
    #facebookShareLink {
      background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/29o2LuViTXWlA0qaxmGI3r/a15177d6827f833863d5b749393938c1/social-icon-facebook.svg');
      &:hover {
        background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/1ObxVhyv8eXvsyPIN2FSMt/af3ee8b33f0c54d76867a4bfaeffa82d/social-icon-facebook-active.svg');
      }
    }
    #twitterShareLink {
      background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/1ubrlr51QSdAt317WI3si6/b7582b4536d51aac9b6b5c3ef95b209d/social-icon-twitter.svg');
      &:hover {
        background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/75FS5yPgYGmGCI17cXxwUK/a46c944b06016f34f00ff33aa123bb75/social-icon-twitter-active.svg');
      }
    }
    #linkedinShareLink {
      background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/ERFvp7N0yX4jVLMtgqsTA/7300d0ff2bfb9943524575312ff572c9/social-icon-linkedin.svg');
      &:hover {
        background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/55cRr6Qk7NpqXuxJLe1WKZ/6b58632f0febd2a3934aef04d0894222/social-icon-linkedin-active.svg');
      }
    }
    #emailShareLink {
      background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/8wm7q6PFVbzv1eBNfC0lO/1e167f4b0652e15abc2377766a7b495a/social-icon-email.svg');
      &:hover {
        background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/7cxXSzBJ9fJj4KOPG8YYW4/69a3c1c174ed7279dd65c68dc87615da/social-icon-twitter-email-active.svg');
      }
    }
  }
</style>
